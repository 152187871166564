(function($, Drupal) {
  Drupal.behaviors.ada = {
    attach: function() {
      var $tabbableElements = $();
      var timer;

      $(document).off('keydown').on('keydown', function(e) {
        var key = e.key;
        if (['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp', 'Enter', 'Escape', ' '].indexOf(key) > -1) {
          $tabbableElements = $(':tabbable');
          var $currentElementFocused = $(':focus');
          var index = $tabbableElements.index($currentElementFocused);

          switch (key) {
            case 'ArrowLeft':
            case 'ArrowUp':
              if (index === 0) {
                // If the element is the first then focus the last element.
                focusElement(-1);
              } else {
                focusElement(index - 1);
              }
              break;
            case 'ArrowRight':
            case 'ArrowDown':
              if (index === $tabbableElements.length - 1) {
                // If the element is the last then focus the first element.
                focusElement(0);
              } else {
                focusElement(index + 1);
              }
              break;
            case 'Enter':
            case ' ':
              if (timer) {
                clearTimeout(timer);
              }
              // Click will be fired if the element is diffent than a and button html tag.
              if (!$currentElementFocused.is('a') && !$currentElementFocused.is('button') && !$currentElementFocused.is(':checkbox')) {
                $currentElementFocused.click();
              }
              if ($currentElementFocused.hasClass('close-popup')) {
                // Focus previous element after a time in case it takes time to load
                focusTimer(index - 1);
              }
              // If the link/label has pop up then click to open
              if ($currentElementFocused[0].hasAttribute('aria-haspopup')) {
                // Focus next element after a time in case it takes time to load
                focusTimer(index + 1);
              }
              break;

            case 'Escape':
              focusElement(index - 1);
              break;
          }
        }
      });

      function focusElement(index) {
        $tabbableElements.eq(index).focus();
      }

      function focusTimer(index) {
        timer = setTimeout(function() {
          // Update the var to get all the new tabbable elements.
          $tabbableElements = $(':tabbable');
          focusElement(index);
        }, 350);
      }
    }
  };
})(jQuery, Drupal);
